<template>
	<div>
		<CRow>
			<CCol class="text-right mb-4">
				<CButton
					class="transparent"
					color="secondary"
					@click="$refs['modal-add'].open()"
				>
					<FontAwesomeIcon
						:icon="['far', 'plus']"
						class="icon-left"
					/>
					Create synonyms
				</CButton>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<form class="search-form" @submit.prevent="handleSearch">
					<CInput
						v-model.trim="queryParams.q"
						type="text"
						placeholder="Search by synonyms word"
					>
						<template #prepend-content>
							<button type="submit" class="btn-search">
								<FontAwesomeIcon :icon="['far', 'search']" />
							</button>
						</template>
					</CInput>
				</form>
			</CCol>
		</CRow>
		<CRow>
			<CCol class="mb-4">
				<BaseSearchFormFooter
					:count="list.meta.total"
					append-text="synonyms found"
					@onReset="handleResetFilter"
				/>
			</CCol>
		</CRow>
		<BaseTable
			:is-loading="list.isLoading"
			:fields="FIELDS"
			:items="dataTable"
			:pagination="{
				pages: list.meta.lastPage,
				activePage: list.meta.currentPage,
			}"
			class="table-custom"
			striped
			clickable-rows
			@onPaginationClick="handlePageChange"
		>
			<template #no-items-view>
				<div class="empty-table-element">
					<p class="subtitle">
						{{ $t('global.searchNotFound', { field: 'synonyms' }) }}
					</p>
				</div>
			</template>
			<template #id="{item}">
				<div class="text-right">
					<CButton
						class="transparent p-0"
						@click="openModalDelete(item)"
					>
						<CIcon
							class="icon-trash"
							name="cil-trash"
						/>
					</CButton>
				</div>
			</template>
		</BaseTable>

		<ModalCreateSynonym
			ref="modal-add"
			:handle-create="createSearchSynonym"
			title="Create synonym"
			@onSuccess="handleCreateSuccess"
		/>
		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteSearchSynonym.bind(null, selectedKeyId)"
			title="Remove this synonym?"
			description="Are you sure you want to remove this synonym?"
			@onSuccess="handleDeleteSuccess"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ModalCreateSynonym from '@/components/ModalCreateSynonym.vue';
import { cleanObject, randomSearchString } from '../assets/js/helpers';

const FIELDS = [
	{ key: 'keywords', label: 'Synonym', class: 'col-keyword' },
	{ key: 'id', label: '', class: 'col-id' },
];

export default {
	components: {
		ModalCreateSynonym,
	},
	data() {
		return {
			FIELDS,
			selectedKeyId: null,
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				r: randomSearchString(),
			},
		};
	},
	computed: {
		...mapState('searchSynonyms', {
			list: 'list',
		}),
		...mapGetters({
			synonyms: 'searchSynonyms/synonymList',
		}),

		dataTable() {
			return this.synonyms.map((item) => ({
				...item,
				keywords: item.keywords ? item.keywords.join(', ') : '-',
			}));
		},
	},
	created() {
		this.getSearchSynonyms(this.queryParams);
	},
	methods: {
		...mapActions({
			getSearchSynonyms: 'searchSynonyms/getSearchSynonyms',
			createSearchSynonym: 'searchSynonyms/createSearchSynonym',
			deleteSearchSynonym: 'searchSynonyms/deleteSearchSynonym',
		}),
		updateUrlParams() {
			const query = cleanObject(JSON.parse(JSON.stringify(this.queryParams)));
			this.$router.push({ query }).catch(() => {});
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handleResetFilter() {
			this.queryParams = {
				q: null,
				page: null,
				r: null,
			};

			this.updateUrlParams();
		},
		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
		async handleCreateSuccess() {
			this.queryParams.page = null;
			this.queryParams.q = '';
			await this.getSearchSynonyms(this.queryParams);
		},
		async handleDeleteSuccess() {
			this.selectedKeyId = null;
			this.queryParams.q = '';
			this.queryParams.page = null;
			await this.getSearchSynonyms(this.queryParams);
		},
		openModalDelete(id) {
			this.selectedKeyId = id;
			this.$refs['modal-remove'].open();
		},
	},
};
</script>

<style lang="scss" scoped>
	.btn-wrapper {
		max-width: rem(132);
	}

	.btn-search {
		background: transparent;
		box-shadow: none;
		border: none;
		padding: 0;
	}

	.icon-trash {
		color: $color-black-25;
	}

	::v-deep .col-keyword {
		z-index: 1;
	}
</style>
