<template>
	<CModal
		ref="modal"
		:show.sync="isShow"
		centered
		data-test-id="modal"
		@update:show="close"
	>
		<span data-test-id="modal-title">{{ title }}</span>
		<template #header-wrapper>
			<ModalHeaderWrapper :title="title" @onClose="close" />
		</template>
		<template #default>
			<p>
				Put keywords with the same meaning in the box. Separate each word with ( | ). Allow only 20 keywords per set.
			</p>

			<CTextarea
				ref="input-text"
				v-model="$v.inputText.$model"
				:is-valid="!$v.inputText.$error && null"
				data-test-id="input-text"
				placeholder="Laptop|Notebook|แล็บทอป|โน๊ตบุ๊ค"
				rows="3"
				@change="$v.inputText.$touch()"
			>
				<template #invalid-feedback>
					<div
						v-if="!$v.inputText.required"
						class="invalid-feedback"
						data-test-id="invalid-required"
					>
						Field is required
					</div>
					<div
						v-else-if="!$v.inputText.maxLenght"
						class="invalid-feedback"
						data-test-id="invalid-max-length"
					>
						Keywords must not exceed {{ $v.inputText.$params.maxLenght.max }} letters.
					</div>
					<div
						v-else-if="!$v.inputText.maxKeyword"
						class="invalid-feedback"
						data-test-id="invalid-max-keyword"
					>
						Keywords must not exceed {{ MAXIMUM_SYNONYM_KEYWORDS }} words.
					</div>
					<div
						v-else-if="!$v.inputText.minKeyword"
						class="invalid-feedback"
						data-test-id="invalid-min-keyword"
					>
						Keywords must be at least {{ MINIMUM_SYNONYM_KEYWORDS }} words.
					</div>
				</template>
			</CTextarea>
		</template>
		<template #footer>
			<CButton
				:disabled="isSubmitting"
				class="modal-button transparent mr-3"
				color="tertiary"
				data-test-id="cancel-button"
				@click="close"
			>
				Cancel
			</CButton>
			<CButton
				:disabled="$v.$invalid || isSubmitting"
				class="modal-button transparent"
				color="primary"
				data-test-id="primary-button"
				@click="handleSubmit"
			>
				<span>
					{{ isSubmitting ? 'Creating' : primaryButtonText }}
				</span>
			</CButton>
		</template>
	</CModal>
</template>

<script>

import { required, maxLength } from 'vuelidate/lib/validators';
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';

const MAXIMUM_SYNONYM_KEYWORDS = 20;
const MINIMUM_SYNONYM_KEYWORDS = 2;

export default {
	name: 'ModalCreateAttribute',
	components: {
		ModalHeaderWrapper,
	},
	props: {
		title: {
			type: String,
			default: 'Untitled',
		},
		primaryButtonText: {
			type: String,
			default: 'Create',
		},
		handleCreate: {
			type: Function,
			default: () => {},
		},
	},
	data() {
		return {
			MINIMUM_SYNONYM_KEYWORDS,
			MAXIMUM_SYNONYM_KEYWORDS,
			isShow: false,
			isSubmitting: false,
			inputText: '',
		};
	},
	validations: {
		inputText: {
			required,
			maxLenght: maxLength(255),
			maxKeyword: (input) => {
				const keywords = input.split('|');
				return keywords.length <= MAXIMUM_SYNONYM_KEYWORDS;
			},
			minKeyword: (input) => {
				const keywords = input.split('|');
				return keywords.length >= MINIMUM_SYNONYM_KEYWORDS;
			},
		},
	},
	methods: {
		async open() {
			this.isShow = true;
			await this.$nextTick();

			this.$refs['input-text'].$el.querySelector('textarea').focus();
		},
		close() {
			this.inputText = '';
			this.$v.$reset();
			this.isShow = false;
		},
		async handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			const keywords = this.inputText.split('|').map((keyword) => keyword.trim());

			this.isSubmitting = true;
			try {
				await this.handleCreate([...new Set(keywords)]);
				this.isShow = false;
				this.inputText = '';
				this.$v.$reset();
				this.$emit('onSuccess');
			} catch (e) {
				this.$emit('onFailure');
			}
			this.isSubmitting = false;
		},
	},
};
</script>
